nav[aria-label="breadcrumb"] {
  display: none;
}

.sidebar {
  display: none !important;
}

#headerMenuCollapse {
  display: none;
}

.offset-2 {
  margin-left: 0 !important;
}

.card-header {
  display: block !important;
  padding: 0.5rem 0;
  margin-bottom: 1rem;
}

.text-muted,
.cell-title,
.table th {
  color: #000 !important;
}
